import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IUserDTO } from '../dto/user/user.dto';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public constructor(private readonly _httpClient: HttpClient) {}

  public fetchUserByUsername(
    username: string,
    recaptchaToken: string
  ): Observable<IUserDTO> {
    const httpParams: HttpParams = new HttpParams()
      .append('username', username)
      .append('gr', recaptchaToken);
    return this._httpClient.get<IUserDTO>('/api/v1/public/users', {
      withCredentials: true,
      params: httpParams,
    });
  }
}
